.faq {
    &__item {
        margin-bottom: 2rem;
        transition: transform 0.25s ease;
        &:link {
            text-decoration: none;
            &:hover,
            &:focus {
                transform: scale(1.02);
                .tasks__item-arrow {
                    animation: nextArrow 1s ease infinite;
                }
            }
        }
    }
    &__section {
        display: flex;
        margin-bottom: 1.0625rem;
        margin-top: 2.0625rem;
    }
    &__section-intro {
        padding: 0rem 1.375rem;
        .title--h3 {
            margin-bottom: 0;
        }
    }
    &__questions {
        border-radius: 20px;
        background-color: $lightTurquoise;
    }
    &__question {
        position: relative;
        &:not(:last-child):after {
            content: '';
            display: block;
            height: 2px;
            background: rgba(56, 56, 56, 0.12);
            bottom: 0;
            position: absolute;
            width: auto;
            left: 22px;
            right: 22px;
        }
        &--active {
            .faq__icon {
                transform: rotate(90deg);
            }
        }
    }
    &__icon {
        transform: rotate(0);
        transition: transform 0.125s ease-out;
    }
    &__click-handler {
        appearance: none;
        background: transparent;
        border: 0;
        width: 100%;
        text-align: left;
        display: flex;
        justify-content: space-between;
        padding: 18px 38px;
        cursor: pointer;
        transition: background 0.25s ease;
        justify-content: center;
        align-items: center;
        &:hover,
        &:focus {
            background: rgba(0, 0, 0, 0.05);
            outline: none;
        }
        .paragraph--question {
            flex-basis: calc(100% - 21px);
            font-size: 18px;
        }
    }
    &__answer {
        padding: 18px 38px;
        animation: appear 0.25s 0.125s ease forwards;
        opacity: 0;
    }
    .icon {
        align-self: flex-start;
        padding-top: 9px;
    }
    .paragraph {
        margin-bottom: 0;
    }
}
