.bar {
    background: rgba(white, 0.3);
    width: 100%;

    background: $dark;
    box-shadow: 0 0 1.25rem 0.625rem rgba(0, 0, 0, 0.4);
    &__progress {
        min-width: 4.375rem;
        transition: width 0.5s ease;
        background-image: linear-gradient(-90deg, $meadow 0%, $tealish 100%);
        height: 0.375rem;
        float: left;

        box-shadow: 0 0 0.625rem 0.3125rem rgba($meadow, 0.5);
        border-radius: 0 0.1875rem 0.1875rem 0;
    }
}
