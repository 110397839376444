.progress-bar {
    display: flex;
    width: 100%;
    list-style: none;
    padding: 0;
    margin: 0;
    position: relative;
    opacity: 0;
    animation: appear 0.5s 0.5s ease forwards;
    &__item {
        flex-grow: 1;
        text-align: center;
        padding: 1rem;
    }
    .percentage {
        background-color: $dark;
        box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.4);
        color: white;
        position: absolute;
        height: auto;
        animation: confirm-action-dialogue 0.25s ease;
        transform: translateY(-100%) translateX(50%);
        padding: 8px 16px;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        right: 0;
        top: -16px;
        &:after {
            content: '';
            border-top: 12px solid $dark;
            border-left: 12px solid transparent;
            border-right: 12px solid transparent;
            position: absolute;
            right: 0;
            left: 0;
            width: 0;
            margin: auto;
            top: 30px;
        }
    }
    .caption {
        font-style: normal;
        display: inline-block;
    }
    &__bar,
    &:before {
        content: '';
        position: absolute;
        bottom: -1px;
        height: 5px;
    }
    &:before {
        width: 100%;
        background: $lynch;
    }
    &__bar {
        width: 0;
        transition: width 0.25s 0.5s ease-in-out;
        height: 5px;
        border-radius: 4px;
        box-shadow: 0 0 10px 0 rgba($saffron, 0.5);
        background-image: linear-gradient(to left, adjust-hue($saffron, -13deg), $saffron);

        cursor: pointer;
        border: 0px solid #010101;
        &--full {
            box-shadow: 0 0 10px 0 rgba($meadow, 0.5);
            background-image: linear-gradient(to right, $meadow, $tealish);
        }
    }
}

@media all and (max-width: 768px) {
    .progress-bar {
        width: 100%;
    }
}
