.signature {
    background: $blueBg;
    padding: 1.375rem 1.375rem 1.75rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 2.5rem;
    &--more {
        margin: 0 -2.75rem 2.0625rem;
        padding: 2.0625rem 2.75rem;
    }

    &__panel {
        width: 45%;
        position: relative;
        &--card {
            width: 49%;
            margin-bottom: 2%;
        }
        &--full {
            width: 100%;
            margin-bottom: 0.6875rem;
        }
        &--bottom {
            justify-content: flex-end;
            display: flex;
        }
        &--spacer {
            margin-bottom: 2.0625rem;
        }
        &--firm-signing {
            background: white;
            margin-bottom: 2%;
            padding: 20px;
            .title__img {
                animation: mini-shake 1.5s infinite ease;
            }
        }
        p:last-child {
            margin-bottom: 0;
        }
        .form {
            margin-top: 20px;
        }
    }

    &__loader {
        position: relative;
        width: 50px;
        padding: 10px;
        .spinner {
            width: 30px;
            height: 30px;
            left: 10px;
            right: 10px;
            top: 10px;
            bottom: 10px;
        }
    }

    &__list {
        @extend .paragraph;
        line-height: normal;
        list-style: none;
        padding: 0;
        margin: 0;
        margin-bottom: 1.375rem;
    }

    &__list-item {
        margin-bottom: 0.3125rem;
    }

    .title {
        margin-bottom: 0.25rem;
    }

    .paragraph {
        margin-bottom: 1.875rem;
    }
    .caption {
        margin-bottom: 0;
    }
    &__firm-signing {
        font-size: 0.875rem;
        padding: 0;
        list-style-type: none;
    }
    &__firm-signing-item {
        margin-bottom: 4px;
    }
    &__card {
        display: flex;
        background: white;
        padding: 1.25rem;
        flex-wrap: wrap;
        height: 100%;
        opacity: 0;
        animation: slide-in 0.5s ease forwards;
        .title--h3,
        .paragraph,
        .caption {
            margin-bottom: 0;
        }
    }

    &__card-info {
        &--full {
            margin-top: 1.375rem;
            width: 100%;
            &:last-child {
                align-self: flex-end;
            }
        }
    }
    &__card-image {
        margin-right: 1.375rem;
    }

    &__check {
        vertical-align: middle;
        margin-right: 0.625rem;
    }

    &__date {
        @extend .caption;
        vertical-align: middle;
        line-height: 1;
        white-space: nowrap;
    }

    .input {
        margin-bottom: 0.6875rem;
    }

    .form {
        margin-bottom: 0;
        :last-child {
            margin-bottom: 0;
        }
    }

    .form__async-status {
        background: white;
        &--error {
            background: $saffron;
        }
    }
}

@media all and (max-width: 768px) {
    .signature {
        &__panel {
            width: 100%;
        }
        &--more {
            margin: 0 -1.875rem 2.0625rem;
            padding: 1.875rem 2.0625rem;
        }
    }
}
