// Variables
@import 'styles/colors';

// Lib
@import 'styles/lib/keyframes';
@import 'styles/lib/normalize/normalize';
@include normalize();

// Global
@import 'styles/overrides';
@import 'styles/typography';

// Modules
@import 'styles/modules/spinner';
@import 'styles/modules/content';
@import 'styles/modules/button';
@import 'styles/modules/page';
@import 'styles/modules/input';
@import 'styles/modules/form';
@import 'styles/modules/navigation';
@import 'styles/modules/kyc';
@import 'styles/modules/terms-conditions';
@import 'styles/modules/signature';
@import 'styles/modules/offer';
@import 'styles/modules/table';
@import 'styles/modules/hamburger';
@import 'styles/modules/bar';
@import 'styles/modules/step';
@import 'styles/modules/select';
@import 'styles/modules/agreement-list';
@import 'styles/modules/close';
@import 'styles/modules/contact-info';
@import 'styles/modules/modal';
@import 'styles/modules/account-list';
@import 'styles/modules/account-loader';
@import 'styles/modules/commitment-picker';
@import 'styles/modules/validate-account';
@import 'styles/modules/cookie-consent';
@import 'styles/modules/user-handler';
@import 'styles/modules/session-handler';
@import 'styles/modules/tasks';
@import 'styles/modules/manual-handling';
@import 'styles/modules/confirm-action';
@import 'styles/modules/faq';
@import 'styles/modules/progress';
@import 'styles/modules/slider';
@import 'styles/modules/loan-application';
@import 'styles/modules/reciept';
@import 'styles/modules/offerlandingpage';
@import 'styles/modules/animations';
@import 'styles/modules/add-contact-details-and-send-onboarding';
@import 'styles/modules/bankId';
