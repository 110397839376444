.input {
    display: block;
    margin-bottom: 2.0625rem;
    transition: margin 0.125s ease;
    &__label {
        font-size: 0.875rem;
        margin-bottom: 0.75rem;
        display: block;
        font-weight: normal;
    }
    &__field {
        appearance: none;
        border: none;
        width: 100%;
        border-radius: 0.3125rem;
        box-shadow: 0 0.375rem 0.625rem 0.0625rem rgba(0, 0, 0, 0.2);
        background-color: white;
        padding: 0.875rem 1.125rem;
        font-family: 'Open Sans', sans-serif;
        color: $dark;
        font-size: 1rem;
        line-height: normal;
        &::placeholder {
            opacity: 1;
            font-weight: 300;
            line-height: normal;
            color: rgba(0, 0, 0, 0.54);
        }
        &--chip {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: center;
            z-index: 2;
            position: relative;
            padding: 0 1.125rem;
            .button--chip {
                margin: 0.3125rem 0.625rem 0.3125rem 0;
                vertical-align: middle;
                background-color: $fgGreen;
            }
            .input__field {
                margin: 0.875rem 0;
                vertical-align: middle;
                display: inline-block;
            }
        }
        &--invisible {
            padding: 0;
            margin: 0;
            background: transparent;
            box-shadow: none;
            height: auto;
            width: auto;
            flex-grow: 1;
            &:focus {
                outline: none;
            }
        }
    }
    &__required-information {
        font-style: italic;
        font-size: 0.75rem;
        color: rgba(0, 0, 0, 0.6);
        margin: 0.9375rem 0 0.625rem;
        position: absolute;
        &--narrow {
            margin: 10px 0 1rem;
        }
    }
    &__text-area {
        @extend .input__field;
        box-sizing: border-box;
        resize: vertical;
        min-height: 3.125rem;
    }
    &--radio,
    &--checkbox {
        margin-bottom: 0.5rem;
        position: relative;
        .input__label {
            user-select: none;
            margin-bottom: 0;
            width: calc(100% - 2.4375rem);
            min-height: 2.0625rem;
            border-radius: 0.3125rem;
            box-shadow: 0 0.1875rem 0.5rem 0.125rem rgba(0, 0, 0, 0.2);
            background-color: white;
            display: inline-block;
            padding: 0.5rem 1rem;
            font-size: 0.75rem;
            line-height: normal;
            cursor: pointer;
            transition: background 0.25s ease, color 0.25s ease;
        }
    }
    &--checkbox {
        &-alt {
            box-shadow: 0 0.1875rem 0.5rem 0.125rem rgba(0, 0, 0, 0.2);
            background-color: white;
            border-radius: 0.3125rem;
            width: 100%;
            .input__label {
                vertical-align: middle;
                box-shadow: none;
                background-color: none;
            }
            .input__box {
                margin: 0;
            }
            .input__check {
                right: 0.75rem;
                left: auto;
            }
            .input__checkbox:checked ~ .input__label {
                background: none;
                color: $fgGreen;
                font-weight: 600;
            }
        }
    }
    &--radio {
        .input__box {
            border-radius: 50%;
        }
        label{
            padding: 0;
        }
    }
    &__radio,
    &__checkbox {
        appearance: none;
        cursor: pointer;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        &:checked {
            ~ .input__label {
                background: $fgGreen;
                color: $creamWhite;
                font-weight: 600;
            }
            ~ .input__box {
                opacity: 0;
                transform: rotate3d(0, 1, 0, 90deg);
            }
            ~ .input__check {
                opacity: 1;
                transform: rotate3d(0, 1, 0, 0) rotate(45deg);
                &:before,
                &:after {
                    content: '';
                    position: absolute;
                    background-color: $lynch;
                    right: 0;
                }
                &:before {
                    width: 0.25rem;
                    height: 100%;
                    top: 0;
                }
                &:after {
                    width: 60%;
                    height: 0.25rem;
                    bottom: 0;
                }
            }
        }
    }
    &__box {
        width: 1.0625rem;
        height: 1.0625rem;
        border-radius: 0.1875rem;
        border: 0.125rem solid $gull;
        display: inline-block;
        vertical-align: middle;
        margin-right: 1.0625rem;
        margin-left: 0.3125rem;
        opacity: 1;
        transform: rotate3d(0, 1, 0, 0);
        transition: opacity 0.25s ease, transform 0.25s ease;
    }
    &__check {
        display: inline-block;
        width: 1.375rem;
        height: 1.375rem;
        transform: rotate3d(0, 1, 0, 90deg) rotate(45deg);
        opacity: 0;
        transition: transform 0.125s 0.125s ease, opacity 0.125s 0.125s ease;
        transform-origin: center;
        position: absolute;
        left: 0;
        top: -0.8125rem;
        bottom: 0;
        margin: auto;
    }
    &__autocomplete {
        position: relative;
        z-index: 1;
        top: -1.125rem;
        color: white;
    }
    &__suggestion-list {
        margin: auto;
        padding: 1.875rem 0 0.625rem;
        position: absolute;
        background: $dark;
        width: 90%;
        left: 0;
        right: 0;
        list-style: none;
        text-align: center;
        box-shadow: 0 0.1875rem 0.625rem 0.0625rem rgba(0, 0, 0, 0.4);
        opacity: 0;
        transform-origin: top;
        animation: pop 0.25s ease forwards;
        .button--chip {
            animation: appear 0.25s 0.125s ease forwards;
            margin: auto 0.3125rem;
            background-color: $fgGreen;
        }
        &--static {
            position: static;
        }
        &--buttons {
            .input__suggestion-item {
                padding: 0;
            }
        }
    }
    &__suggestion-item {
        padding: 0.625rem;
        transition: background 0.25s ease;
        &:hover {
            background: darken($dark, 5%);
        }
    }
    &__suggestion-text {
        opacity: 0;
        display: inline-block;
        animation: appear 0.25s 0.125s ease forwards;
        &--button {
            cursor: pointer;
            appearance: none;
            background: transparent;
            display: block;
            color: inherit;
            border: none;
            width: 100%;
            padding: 0.625rem;
            &:focus {
                outline: none;
                background: darken($dark, 10%);
            }
        }
    }
    &--disabled {
        .input {
            &__field {
                opacity: 0.8;
                background-color: darken(white, 5%);
            }
            &__radio,
            &__checkbox {
                ~ .input__label {
                    opacity: 0.8;
                    background-color: darken(white, 5%);
                }
                &:checked {
                    ~ .input__label {
                        opacity: 0.8;
                        background-color: lighten($lynch, 5%);
                    }
                }
            } 
        }
    }
    &--error {
        animation: shake 1s ease;
        margin-bottom: 3.4375rem;
        &.input--checkbox,
        &.input--radio {
            margin-bottom: 0.5rem;
        }
        .input__field:not(.input__field--invisible),
        .input__box {
            outline: $lipstick auto 0.3125rem;
        }
    }
}
