.table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    margin-bottom: 1.875rem;
    &__head {
        .table__cell {
            @extend .caption;
            font-weight: normal;
            padding-bottom: 0.5rem;
            border-bottom: 0.0625rem solid rgba(black, 0.2);
        }
    }
    &__cell {
        text-align: left;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        padding: 0.5rem 0.625rem 0.5rem;
        &:last-child {
            text-align: right;
        }
    }
}
