body {
    font-family: 'Inter', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: $textColor;
    font-size: 100%;
}

button,
p {
    font-family: 'Inter', sans-serif;
}
.title,
h1,
h2,
h3 {
    margin-top: 0;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    font-weight: bold;
    margin-bottom: 1.375rem;
    margin-top: 0;
    color: $textColor;
    font-family: 'Inter', sans-serif;
    &:after {
        content: '';
        display: table;
        clear: both;
    }
}
h1,
.title--h1 {
    font-size: 2.25rem;
}
h2,
.title--h2 {
    font-size: 1.625rem;
    font-weight: 600;
}

h3,
.title--h3 {
    font-size: 1.375rem;
    font-weight: 600;
}

h4,
.title--h4 {
    font-size: 1.125rem;
    font-weight: 600;
}

.title {
    &--huge {
        margin: auto auto 1.375rem;
        display: block;
        width: 100%;
        text-align: center;
    }
    &__tiny {
        font-size: 2.25rem;
        line-height: 1;
        font-weight: normal;
    }
    &__huge {
        font-size: 6.0625rem;
        display: block;
        line-height: 1;
        font-weight: bold;
    }
    &__img {
        display: inline-block;
        vertical-align: middle;
        max-height: 1em;
        margin-right: 0.5em;
        &--large {
            margin-right: 1em;
            max-height: initial;
        }
    }
}

.title--spacer {
    margin-bottom: 1.25rem;
}

.data {
    font-weight: 600;
    &--sell {
        color: $meadow;
    }
    &--wait {
        color: $saffron;
    }
}

p,
.paragraph {
    color: $textColor;
    font-size: 1rem;
    line-height: normal;
    margin-bottom: 1.375rem;
    margin-top: 0;

    &--spacer {
        margin-bottom: 2.75rem;
    }
    &--small {
        font-size: 0.875rem;
    }
}

ol {
    list-style-type: lower-roman;
    @extend .paragraph;
}

.caption {
    font-size: 0.875rem;
    font-style: italic;
    line-height: normal;
    color: $textColor;
}

.link {
    transition: all 0.125s ease;
    &:link,
    &:visited,
    &:active {
        color: darken($blue, 20%);
        font-weight: 600;
        text-decoration: none;
    }
    &:hover {
        color: darken($blue, 10%);
    }
    &:active {
        color: $blue;
    }
}

@media all and (max-width: 768px) {
    h1,
    .title {
        font-size: 1.625rem;
    }
    h2,
    .title--h2 {
        font-size: 1.25rem;
    }
    h3,
    .title--h3 {
        font-size: 1.125rem;
    }

    h4,
    .title--h4 {
        font-size: 1rem;
    }
}
