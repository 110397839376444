.navigation {
    width: 100%;
    position: relative;
    display: flex;
    backdrop-filter: blur(3px);
    overflow: hidden;
    min-height: 4.375rem;
    background-color: $aqua;
    color: $textColor;
    &__arrow {
        margin-right: 1rem;
    }
    &__back {
        animation: slide-in 0.5s ease;
        pointer-events: all;
        z-index: 1;
        display: flex;
        align-items: center;
        transition: transform 0.25s ease;
        margin: auto 0;
        &,
        &:link,
        &:active {
            color: $textColor;
            text-decoration: none;
            font-weight: bold;
            padding: 10px 20px;
            margin: 0 10px;
        }
        &:hover {
            .navigation__arrow {
                animation: previousArrow 1s ease infinite;
            }
        }
    }
}

@media all and (max-width: 768px) {
    .navigation {
        &__wrapper {
            padding: 0;
        }
        &__item {
            flex-basis: 100%;
            justify-content: center;
        }
    }
}
