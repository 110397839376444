.offer-slider {
    border-radius: 20px;
}

.offer-slider .noUi-conntects {
    border-radius: 20px;
    overflow: hidden;
}

.offer-slider.noUi-target {
    border-radius: 20px;
}

.offer-slider .noUi-connects {
    border-radius: 20px;
}

.offer__calc {
    background-color: $aqua;
    max-width: 400px;
    margin: 20px;
    z-index: 4;
}

.offer__selectedvalue {
    font-weight: bold;
    margin-bottom: 12px;
}

.offer__sliderlabel {
    font-weight: bold;
    margin: 0;
    font-size: 16px;
}

.calc__header {
    margin-top: 20px;
    font-weight: bold;
    font-style: normal;
}

.calc__example {
    display: flex;
    align-content: space-between;
    margin-left: 20px;
    margin-right: 20px;
    &__itemcost {
        margin-left: auto;
        &--bold {
            font-weight: bold;
        }
    }
    &__middle {
        margin-left: 5px;
    }
}

.calc__example:last-child {
    padding-bottom: 26px;
}

.calc__summary {
    margin-top: 20px;
    padding-bottom: 20px;
}

.offer__buttons {
    padding-top: 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.offer__buttons div {
    min-width: 240px;
    margin: 5px;
}

.calc__tooltip {
    margin-top: 16px;
}

.ModalAppear {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
