.content {
    width: 100%;
    position: relative;
    display: flex;
    overflow: hidden;
    &__logo {
        position: absolute;
        z-index: 9;
        top: 0;
        left: 0;
        height: 6.75rem;
    }
    &__image {
        position: relative;
        overflow: hidden;
        z-index: 0;
        width: 100%;
        height: 100%;
        flex-grow: 1;
        > .switch-wrapper {
            overflow: hidden;
            position: absolute;
            bottom: 0;
            top: 0;
            height: 100%;
            width: 100%;
        }
        &--error {
            position: relative;
            background-image: url('/img/error-bg.jpg');
            background-position: center;
            background-size: cover;
        }
    }
    &__section {
        margin-bottom: 1.875rem;
    }
    &__background {
        margin: auto;
        width: 100%;
        height: 100%;
        transform: scale(1.2);
        &:before {
            content: '';
            background-image: radial-gradient(circle at 50% 50%, rgba($dark, 0.1) 50%, rgba($dark, 0.6) 100%);
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: 0;
            z-index: 1;
        }

        &-img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-size: cover;
            background-position: center;
        }
    }
    &__main {
        box-shadow: 0 0 2.5rem 1.25rem rgba(0, 0, 0, 0.4);
        position: relative;
        overflow: auto;
        display: flex;
        flex-basis: 66.666%;
        flex-grow: 2;
        flex-direction: column;
        transition: flex-basis 0.125s ease;
        &--half {
            flex-basis: 50%;
            flex-grow: 1;
        }
        > .switch-wrapper {
            flex-grow: 2;
        }
    }
    &__navigation {
        position: sticky;
        pointer-events: none;
        top: 0;
        align-self: flex-start;
        width: 100%;
        z-index: 3;
    }
    &__wrapper {
        display: flex;
        flex-direction: column;
        height: 100%;
    }
    &__body {
        padding: 2.0625rem 5.625rem;
        width: 100%;
        max-width: 60.625rem;
        margin: auto;
        background-color: $creamWhite;
        &--full {
            max-width: none;
        }
    }
    &__scroll-arrow {
        position: fixed;
        bottom: 140px;
        right: 20px;
        opacity: 0;
        transition: 0.25s ease;
        z-index: 9;
        &--active {
            opacity: 1;
        }
    }
    &__no-nav {
        .content__body {
            padding: 6.4375rem 5.625rem 2.0625rem;
            &--centered {
                padding: 2.0625rem 5.625rem;
            }
        }
    }
    &__error-image {
        position: absolute;
        right: 0;
        bottom: 0;
    }
    &__spinner {
        position: absolute;
        height: 100px;
        width: 100px;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        overflow: hidden;
        &--inline {
            position: relative;
            display: inline-block;
            height: 1em;
            width: 1em;
            margin: 0 1rem;
            .spinner {
                height: 1em;
                width: 1em;
            }
        }
    }
    &__checklist {
        ul {
            list-style: outside none;
            > li::before {
                content: ' ';
                display: inline-block;
                background-size: contain;
                width: 1em;
                height: 1em;
                position: relative;
                top: 0.2rem;
                margin-right: 1rem;
                margin-left: -1.7em;
                background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='24' height='24' rx='12' fill='%230D5257'/%3E%3Cpath d='M17.0333 8.4074C16.9636 8.3371 16.8806 8.2813 16.7893 8.24323C16.6979 8.20515 16.5998 8.18555 16.5008 8.18555C16.4018 8.18555 16.3038 8.20515 16.2124 8.24323C16.121 8.2813 16.038 8.3371 15.9683 8.4074L10.3808 14.0024L8.03332 11.6474C7.96093 11.5775 7.87547 11.5225 7.78183 11.4856C7.68819 11.4487 7.58819 11.4306 7.48756 11.4323C7.38692 11.4341 7.28762 11.4556 7.19531 11.4957C7.103 11.5358 7.0195 11.5938 6.94957 11.6661C6.87964 11.7385 6.82465 11.824 6.78775 11.9176C6.75085 12.0113 6.73275 12.1113 6.73449 12.2119C6.73623 12.3125 6.75778 12.4118 6.7979 12.5042C6.83802 12.5965 6.89593 12.68 6.96832 12.7499L9.84832 15.6299C9.91804 15.7002 10.001 15.756 10.0924 15.7941C10.1838 15.8321 10.2818 15.8517 10.3808 15.8517C10.4798 15.8517 10.5779 15.8321 10.6693 15.7941C10.7606 15.756 10.8436 15.7002 10.9133 15.6299L17.0333 9.5099C17.1094 9.43966 17.1702 9.35443 17.2118 9.25955C17.2533 9.16468 17.2748 9.06222 17.2748 8.95865C17.2748 8.85507 17.2533 8.75262 17.2118 8.65774C17.1702 8.56287 17.1094 8.47763 17.0333 8.4074Z' fill='%23F1F5F5'/%3E%3C/svg%3E%0A");
            }
            > li {
                font-size: 18px;
                margin-bottom: 0.445rem;
                line-height: 26.1px;
                font-size: 18px;
            }
        }
    }
}

@media all and (min-width: 1440px) {
    .content {
        &__body {
            max-width: 75rem;
        }
    }
}

@media all and (min-width: 1080px) {
    .content {
        &__body {
            &--centered {
                height: 100%;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                align-content: center;
                margin-bottom: 0 !important;

                > {
                    .title,
                    .paragraph {
                        display: block;
                        width: 100%;
                    }
                }
            }
        }
    }
}

@media all and (max-width: 1079px) {
    .content {
        overflow: auto;
        flex-wrap: wrap;
        &__main,
        &__image {
            width: 100%;
            flex-basis: initial;
        }
        &__main {
            order: 2;
            min-height: calc(100% - 30vh);
            box-shadow: 0 -0.625rem 1.25rem 0.625rem rgba(0, 0, 0, 0.4);
            overflow: visible;
        }
        &__image {
            height: auto;
            min-height: initial;
            order: 1;
            display: flex;
            flex-direction: column;
            > .switch-wrapper {
                order: 1;
                display: block;
                overflow: hidden;
                position: relative;
                padding-top: 56.25%;
                > div {
                    top: 0;
                }
            }
            .session-handler {
                order: 2;
            }
        }
        &__no-nav .content__body {
            padding: 6.4375rem 5.625rem 2.0625rem;
        }
    }
}

@media all and (max-width: 768px) {
    .content {
        &__no-nav .content__body {
            padding: 2.5rem 1.875rem 5.625rem;
        }
        &__body {
            padding: 1.875rem 1.875rem 5.625rem;
        }
    }
}
