.terms-conditions {
    height: 30vh;
    position: relative;
    margin-bottom: 6.375rem;
    transition: height 0.25s ease;
    width: 100%;
    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        width: calc(100% - 0.5rem);
        height: 6.25rem;
        left: 0;
        pointer-events: none;
        background-image: linear-gradient(to bottom, rgba(white, 0), rgba(white, 0.05), white);
        z-index: 2;
    }
    &__content {
        height: 100%;
        width: 100%;
        padding-bottom: 1.875rem;
        padding-right: 1.875rem;
        overflow-y: scroll;
        font-family: 'Playfair Display', serif;
        h1,
        h2,
        h3,
        h4 {
            font-weight: 400;
        }
        h1 {
            font-size: 2em;
            margin-top: 0.67em;
            margin-bottom: 0.67em;
            margin-left: 0;
            margin-right: 0;
        }
        h2 {
            font-size: 1.5em;
            margin-top: 0.83em;
            margin-bottom: 0.83em;
            margin-left: 0;
            margin-right: 0;
        }
        h3 {
            font-size: 1.17em;
            margin-top: 1em;
            margin-bottom: 1em;
            margin-left: 0;
            margin-right: 0;
        }
        h1,
        h2,
        h3,
        h4 {
            font-family: 'Playfair Display', serif;
        }
        p,
        ol,
        li {
            font-family: 'Lato', sans-serif;
        }
        p {
            margin-bottom: 1rem;
        }
        li {
            margin-bottom: 0.625rem;
        }

        strong {
            font-weight: 700;
        }
    }
    &__expand {
        text-align: center;
        padding: 0.625rem;
        background: white;
    }
    &__skip {
        margin: 2.125rem 0;
        &:after {
            content: '';
            clear: both;
            display: table;
        }
    }
    &__skip-confirm {
        position: relative;
    }
    ::-webkit-scrollbar-track {
        width: 0.5rem;
        border: 0.125rem solid white;
        background-color: $scrollTrack;
    }
    ::-webkit-scrollbar {
        width: 0.5rem;
        background-color: transparent;
    }
    ::-webkit-scrollbar-thumb {
        width: 0.25rem;
        height: 2.5625rem;
        border-radius: 0.25rem;
        border: 0.25rem solid $scroll;
        background-color: $scroll;
    }
    &--expanded {
        height: 70vh;
    }
    &--small {
        height: auto;
        margin-bottom: 2.5rem;
        .terms-conditions {
            &__content {
                overflow: visible;
                padding-bottom: 0;
                padding-right: 0;
            }
            &__expand {
                display: none;
            }
        }
        &:after {
            content: initial;
        }
    }
}
