.validate-account {
    &__confirmation {
    }
    &__confirmation-account {
        background: $account-gradient;
        padding: 20px;
        position: relative;
        margin: 20px 0;
        .title,
        .caption,
        .paragraph {
            color: white;
            margin-bottom: 0;
        }
        &:before,
        &:after {
            content: '';
            position: absolute;
            top: -0.3125rem;
            bottom: -0.3125rem;
            z-index: -1;
            opacity: 0.3;
            filter: blur(20px);
        }
        &:before {
            left: -0.3125rem;
            right: 40%;
            background-image: radial-gradient(circle at center, #5732ba 80%, transparent);
        }
        &:after {
            right: -0.3125rem;
            left: 40%;
            background-image: radial-gradient(circle at center, #3257ba 80%, transparent);
        }
    }
    &__error {
        margin-bottom: 2rem;
    }
}
