.contacts-info {
    background: #0d5257;
    border-radius: 37px;
    color: #fffefc;
    padding: 33px;
    padding-bottom: 8px;
}

.titles,
.paragraphs {
    color: white;
}

.buttons {
    border: 2px solid white;
    color: white;
}

#send {
    margin-bottom: 20px;
}
