.button {
    background: $fgGreen;
    color: white;
    font-size: 1rem;
    font-weight: bold;
    border-radius: 100px;
    line-height: normal;
    max-width: 100%;
    min-width: 240px;
    padding: 0.875rem;
    text-align: center;
    border: none;
    user-select: none;
    overflow: hidden;
    cursor: pointer;
    position: relative;
    &:before {
        content: '';
        transition: background 0.25s ease;
        position: absolute;
    }
    &:hover:before,
    &:focus:before {
        display: block;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 0;
        background: rgba(255, 255, 255, 0.2);
    }
    &:focus {
        outline-width: 0.125rem;
    }
    &:link {
        text-decoration: none;
    }
    &__img {
        margin-left: 0.5rem;
        display: inline-block;
    }
    &--chip {
        padding: 0.375rem 0.75rem;
        border-radius: 0.3125rem;
        font-size: 0.75rem;
        color: white;
        min-width: auto;
        margin-right: 0.625rem;
        margin-bottom: 0.3125rem;
        opacity: 0;
        animation: appear 0.25s ease forwards;
        white-space: nowrap;
        font-weight: normal;
        &.button--active {
            padding-right: 2.3125rem;
            overflow: hidden;
            &:after {
                content: '';
                display: block;
                width: 1.8125rem;
                height: 1.8125rem;
                background: url('../../img/close.svg');
                background-repeat: no-repeat;
                background-position: center;
                background-color: rgba(0, 0, 0, 0.18);
                position: absolute;
                right: 0;
                top: 0;
            }
        }

        &-default {
            background-color: $meadow !important;
            color: white !important;
        }

        &:hover:before,
        &:focus:before {
            background: rgba(0, 0, 0, 0.2) !important;
            z-index: -1;
        }
    }
    &--action {
        background-color: $fgGreen;
        color: white;
        font-weight: bold;
        border-radius: 100px;
    }
    &--secondary {
        background-color: $creamWhite;
        color: black;
        font-weight: bold;
        border-radius: 100px;
        border: 2px solid rgb(135, 171, 173);
        padding: calc(0.875rem - 2px);
    }
    &--alt {
        background: white;
        color: $textColor;
    }
    &--account {
        background-image: $creamWhite;
    }
    &--cancel {
        background: transparent;
        border: 2px solid white;
        color: white;
    }
    &--full {
        width: 100%;
    }
    &--spacer-top {
        margin-top: 3.4375rem;
    }
    &--small {
        padding: 0.5rem;
        .button__img {
            width: 1rem;
        }
    }
    &--disabled {
        opacity: 0.5;
        pointer-events: none;
    }
    &--wide {
        min-width: 16.625rem;
    }
    &--right {
        float: right;
        margin-top: 0.75rem;
    }

    &--loading {
        .spinner {
            position: static;
            vertical-align: middle;
            margin-left: 0.75rem;
        }
        &.button--secondary {
            .spinner {
                .path {
                    animation: dash $duration ease-in-out infinite;
                    stroke: white;
                }
            }
        }
    }
    &--link {
        background: none;
        color: inherit;
        font-weight: inherit;
        padding: inherit;
        margin: inherit;
    }
    &[disabled] {
        pointer-events: none;
    }

    .spinner {
        height: 22px;
        width: 22px;
        display: inline-block;
        right: 20px;
        left: auto;
    }
}

@media all and (max-width: 768px) {
    .button:not(.button--chip) {
        width: 100%;
        min-width: initial;
    }
    .button--chip {
        white-space: normal;
    }
}
