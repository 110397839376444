.account-loader {
    &__panel {
        margin-bottom: 50px;
        &--message {
            display: flex;
            .caption,
            .title,
            .paragraph {
                display: inline-block;
                vertical-align: middle;
                margin-right: 16px;
                margin-bottom: 0;
            }
            .title {
                min-width: 140px;
            }
            .caption {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }
    &__message {
        flex-grow: 2;
        position: relative;
    }
    .spinner {
        position: static;
        float: right;
        width: 44px;
        height: 44px;
    }
    .paragraph {
        margin-bottom: 0;
    }

    &--small {
        .account-loader {
            &__panel {
                margin-bottom: 0;
                padding: 27.5px 32px;
                background: $dark;
            }
        }
        .caption,
        .title,
        .paragraph {
            color: rgba(white, 0.78);
        }
        .spinner {
            margin-right: 22px;
            margin-left: -22px;
            height: 25px;
            width: 25px;
        }
    }
}

@media all and (max-width: 1079px) {
    .account-loader {
        &__panel {
            flex-direction: column;
        }
    }
}
