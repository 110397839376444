.agreement-list {
    margin: 2rem 0;
    background-color: $lightTurquoise;
    &__item {
        display: flex;
        justify-content: space-between;
        border-bottom: 0.125rem solid rgba(56, 56, 56, 0.12);
        border-radius: 20px;
        padding: 2rem;
        &:last-child {
            border-bottom: none;
            margin-bottom: 0;
        }
        &--done {
            .agreement-list__button {
                background-color: $lightTurquoise;
            }
        }
    }
    &__panel {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        &:last-child {
            align-content: center;
            justify-content: flex-end;
        }

        .paragraph,
        .title,
        .caption {
            width: 100%;
            margin-bottom: 0;
        }
    }
    &__guarantor-shortcut {
        position: relative;
        .confirm-action {
            right: -2rem;
            width: 400px;
        }
    }
    &__guarantor-button {
        font-style: italic;
        margin-top: 18px;
        span,
        img {
            vertical-align: middle;
            display: inline-block;
        }
        img {
            width: 8px;
            margin-left: 10px;
        }
    }
    &__guarantor-info {
        display: flex;
        padding: 1.375rem;
        margin: 1rem auto 0.75rem;
        justify-content: space-between;
        .agreement-list__panel {
            flex-basis: 50%;
            display: flex;
            justify-content: center;
        }
    }

    .progress-bar {
        margin-left: 50px;
        width: calc(100% - 50px);
    }
}

@media all and (max-width: 768px) {
    .agreement-list {
        &__item {
            display: block;
        }
        &__panel {
            margin-bottom: 1rem;
            &:last-child {
                margin-bottom: 0;
            }
        }
        &__guarantor-info {
            display: block;
        }
        .progress-bar {
            margin-left: initial;
        }
    }
}
