.tasks {
    margin-bottom: 5rem;
    &__item {
        display: flex;
        margin-bottom: 2rem;
        background-color: $lightTurquoise;
        transition: transform 0.25s ease;
        border-radius: 20px;
        overflow: hidden;
        &:link {
            text-decoration: none;
            &:hover,
            &:focus {
                transform: scale(1.02);
                .tasks__item-arrow {
                    animation: nextArrow 1s ease infinite;
                }
            }
        }
        &--confirmation {
            background-color: $fgGreen;
            box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.6);
            .title,
            .paragraph {
                color: $creamWhite;
            }
            .button {
                display: inline-block;
            }
            .confirmation-icon {
                margin-bottom: 1rem;
                width: 55px;
            }
            .tasks__item-content {
                padding: 25px;
            }
            &:after {
                content: '';
                position: absolute;
                left: 0;
                right: 0;
                display: block;
            }
        }
    }
    &__item-content {
        padding: 25px 45px 25px 25px;
        flex-grow: 2;
        position: relative;
    }
    &__item-img {
        flex-basis: 150px;
        position: relative;
        flex-shrink: 0;
        &--done,
        &--warning {
            &:after {
                content: '';
                background: rgba(36, 42, 50, 0.4);
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                margin: auto;
                z-index: 1;
            }
        }
        .warning-icon,
        .confirmation-icon {
            animation: slide-in 0.5s ease;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            z-index: 2;
            width: 62px;
            height: 62px;
            object-fit: contain;
        }
    }
    &__item-arrow {
        position: absolute;
        right: 28px;
        bottom: 22px;
        width: 20px;
        height: 20px;
        border-radius: 20px;
    }
    &__img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
    }
}

@media all and (max-width: 768px) {
    .tasks {
        &__item {
            flex-wrap: wrap;
        }
        &__item-img {
            height: 150px;
        }
        &__img {
            width: 100%;
        }
        &__item-img,
        &__item-content {
            flex-basis: 100%;
        }
    }
}
