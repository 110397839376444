.loan-slider {
    border-radius: 20px;
}

.loan-slider .noUi-conntects {
    border-radius: 20px;
    overflow: hidden;
}

.loan-slider.noUi-target {
    border-radius: 20px;
}

.loan-slider .noUi-connects {
    border-radius: 20px;
}

.loan-application__calc {
    background-color: $aqua;
    max-width: 400px;
    margin: 20px;
}

.loan-application-coffee {
    color: $spruce;
    margin: 0 auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: 200px;
}

.loan-application__calc {
    // display: flex;
}

.loan__selectedvalue {
    float: right;
}

.calc__header {
    margin-top: 20px;
    font-weight: bold;
    font-style: normal;
}

.calc__example {
    display: flex;
    align-content: space-between;
    margin-left: 20px;
    margin-right: 20px;
    &__itemcost {
        margin-left: auto;
        &--bold {
            font-weight: bold;
        }
    }
    &__middle {
        margin-left: 5px;
    }
}

.calc__summary {
    margin-top: 20px;
    padding-bottom: 20px;
}

.slider-label-bold {
    font-weight: bold;
}
