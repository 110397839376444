.account-list {
    width: 100%;
    margin-bottom: 30px;
    &__item {
        display: flex;
        justify-content: space-between;
        padding: 20px;
        border-bottom: 2px solid rgba(black, 0.12);
        &:last-child {
            border-bottom: none;
        }
    }

    .title--h4,
    .caption,
    .button {
        margin-bottom: 0;
    }
}
