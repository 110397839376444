.kyc {
    &__wrapper {
        position: relative;
        width: 100%;
    }
    .kyc__hint {
        &--active {
            .kyc__hint-text {
                background-color: $darkish;
                display: block;
            }
        }
    }
    &__hint-trigger {
        position: absolute;
        top: 0;
        right: 0;
        appearance: none;
        background: none;
        border: none;
        padding: 0;
        cursor: pointer;
        display: block;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        transition: background 0.5s ease;
        &:hover,
        &:focus {
            outline: none;
            background: rgba($blue, 0.2);
        }
    }
    &__hint-text {
        display: none;
        animation: hint-appear 0.5s ease forwards;
        opacity: 0;
        position: absolute;
        top: 0;
        background: $dark;
        color: white;
        padding: 16px;
        font-size: 0.75rem;
        font-weight: 600;
        width: 100%;
        right: 0;
        box-shadow: 0 0 40px 5px rgba(0, 0, 0, 0.4);
        &:after {
            content: '';
            position: absolute;
            bottom: -6px;
            right: 7px;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 6px 6px 0 6px;
            border-color: $dark transparent transparent transparent;
        }
    }
    &__hint-icon {
        pointer-events: none;
    }
    &__label {
        padding-right: 41px;
    }
    &__person {
        overflow: visible;
        padding: 2.75rem 0 1.5rem;
        position: relative;
        margin-bottom: 3.4375rem;
        text-align: center;
        &:before {
            background: $lightBg;
            content: '';
            display: block;
            height: 100%;
            left: -2.75rem;
            margin: auto;
            overflow: hidden;
            position: absolute;
            right: -2.75rem;
            top: 0;
            z-index: -1;
            max-width: 100vw;
        }
        .form:not(:first-child) {
            border-top: 0.125rem solid white;
            padding-top: 1.375rem;
            .close {
                top: 10px;
            }
        }
        .kyc__label {
            padding-right: 30px;
        }
        + .kyc__hint {
            top: 44px;
            position: absolute;
            width: 100%;
        }
    }

    &__buttons {
        position: absolute;
        bottom: -1.625rem;
        left: 0;
        right: 0;
        margin: auto;
        text-align: center;
    }

    &__person-image {
        display: inline-block;
        margin-bottom: 1.375rem;
        &:first-child {
            margin-right: 1.375rem;
        }
    }
    &__free-text {
        .input {
            margin-bottom: 0.875rem;
        }
    }
    &__suggestion-header {
        font-size: 0.75rem;
        font-style: italic;
        line-height: normal;
        color: $textColor;
        margin-bottom: 0.625rem;
    }
    .form {
        text-align: left;
        margin-bottom: 3.125rem;
    }

    &--summary {
        padding: 30px;
        background: $blueBg;
        .title--h4 {
            margin-bottom: 4px;
        }
    }
    &__person-summary {
        padding: 20px;
        background: white;
        margin-bottom: 1rem;
        .paragraph {
            margin-bottom: 4px;
        }
    }
    &__confirmation {
        margin: 40px 0;
        opacity: 0;
        animation: slide-in 0.5s 0.5s ease forwards;
        .confirmation-icon {
            width: 50px;
            height: 50px;
            margin-bottom: 30px;
        }
    }
}
