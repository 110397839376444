.offer {
    position: relative;
    margin: 3.125rem 0;
    background-color: $fgGreen;
    padding: 0.325rem;

    &:before,
    &:after {
        content: '';
        position: absolute;
        top: -0.3125rem;
        bottom: -0.3125rem;
        z-index: -1;
        opacity: 0.3;
        filter: blur(20px);
    }
    &:before {
        left: -0.3125rem;
        right: 40%;
        background-image: radial-gradient(circle at center, $fgGreen 80%, transparent);
    }
    &:after {
        right: -0.3125rem;
        left: 40%;
        background-image: radial-gradient(circle at center, adjust-hue($fgGreen, 20) 80%, transparent);
    }
    &__item {
        margin: 0;
        flex-basis: 33.3333%;
        padding: 0.9375rem 0.9375rem;
        flex-grow: 1;
    }
    &__list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        list-style: none;
        padding: 0;
        margin: 0;
        background: white;
    }
    .caption {
        margin-bottom: 0;
        font-style: normal;
    }

    .title--h3 {
        margin-bottom: 0;
        font-size: 1.125rem;
    }
}

@media all and (max-width: 768px) {
    .offer {
        &__item {
            flex-basis: 50%;
        }
        &__help {
            margin-top: 1rem;
            &__button {
                margin-top: 0;
            }
        }
    }
}

@media all and (max-width: 500px) {
    .offer {
        &__item {
            flex-basis: 100%;
        }
        &__help {
            margin-top: 1rem;
            &__button {
                margin-top: 0;
            }
        }
    }
}
