.contact-info-buttons button {
    margin-top: 0;
}

.contact-info {
    .form {
        max-width: 600px;
    }
    .form__async-status {
        color: $textColor;
    }
    &--alt {
        box-shadow: 0 0 2.5rem 0 rgba(0, 0, 0, 0.2);
        background-color: $fgGreen;
        width: 100%;
        padding: 2.75rem 3.75rem;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        &__panel {
            min-width: 15.625rem;
            margin-bottom: 1rem;
            display: inline-block;
            padding: 0 1.875rem;
        }
        .paragraph {
            margin-bottom: 0;
            color: white;
        }
    }
}

@media all and (min-width: 1080px) {
    .contact-info {
        max-width: 100%;
    }
}
