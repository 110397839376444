.step {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 1.25rem;
    position: sticky;
    bottom: 0;
    background-color: $lightTurquoise;
    min-height: 4.875rem;
    z-index: 3;
    &__link {
        display: flex;
        align-items: center;
        transition: transform 0.25s ease;
        margin: auto 0;
        &,
        &:link,
        &:active {
            color: $dark;
            text-decoration: none;
            font-weight: bold;
            padding: 0.625rem;
        }
        &:before {
            content: '';
            background-image: url(/img/left.svg);
            width: 1em;
            height: 1em;
            display: inline-block;
            background-repeat: no-repeat;
            background-size: contain;
            vertical-align: middle;
            margin-right: 0.625rem;
        }
        &:hover {
            transform: scale(1.1);
            &:before {
                animation: previousArrow 1s ease infinite;
            }
        }
        &--next {
            order: 1;
            &:before {
                background-image: url(../../img/right.svg);
                margin-left: 0.625rem;
                margin-right: auto;
                order: 2;
            }
            &:hover:before {
                animation: nextArrow 1s ease infinite;
            }
        }
        &--disabled {
            pointer-events: none;
            opacity: 0.4;
        }
    }

    &--agreement {
        background-color: $fgGreen;
        font-weight: bold;
        color: white;
        display: block;
        min-height: initial;
        transition: background 1s ease;
    }

    &--signed {
        background-color: $fgGreen;
        font-weight: bold;
        color: white;
        display: block;
        min-height: initial;
    }

    .agreement-list {
        width: 100%;
        background: none;
        margin: 0;
        box-shadow: none;
        .paragraph,
        .title,
        .caption {
            color: white;
        }
        &__item {
            padding: 0;
            box-shadow: none;
        }
    }
}

@media all and (max-width: 1079px) {
    .step {
        padding: 1.125rem;
        min-height: 4.625rem;
    }
}

@media all and (max-width: 768px) {
    .step {
        &--agreement {
            .agreement-list__item {
                display: flex;
            }
            .agreement-list__panel {
                margin-bottom: 0;
            }
            .button {
                white-space: nowrap;
            }
        }
        &__link {
            &:focus,
            &:hover {
                transform: none;
                &:before {
                    animation: none;
                }
            }
        }
    }
}
