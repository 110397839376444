.manual-handling {
    background: $blueBg;
    padding: 25px;
    margin-bottom: 30px;
    .warning-icon {
        margin-bottom: 20px;
        width: 60px;
    }
    .title--h1 {
        font-size: 26px;
        margin-bottom: 0.5em;
    }
    .title--h3 {
        font-size: 18px;
        margin-bottom: 0.5em;
    }
    .paragraph {
        font-size: 14px;
    }
}
