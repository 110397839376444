.form {
    margin: 0 auto 2.0625rem auto;
    position: relative;

    &__async-status {
        background: $lightTurquoise;
        width: 100%;
        padding: 1.375rem;
        margin-bottom: 2.0625rem;
        position: relative;

        &--error {
            background: lighten($saffron, 0%);
        }
    }
    &--dark {
        .input__required-information {
            color: lighten($lipstick, 12%);
            font-weight: bold;
        }
    }

    .close {
        position: absolute;
        top: -12px;
        right: -12px;
    }
}
