.session-handler {
    position: absolute;
    bottom: 0;
    left: 0;
    margin: auto;
    right: 0;
    background: $fgGreen;
    z-index: 1;
    display: inline-block;
    width: 100%;
    animation: slide-in 0.5s 0.25s ease forwards;

    opacity: 0;
    transform: translateY(100%);
    &__button {
        appearance: none;
        border: none;
        width: 100%;
        background: rgba(white, 0.2);
        cursor: pointer;
        height: 37px;
        transition: background 0.125s ease;
        &:hover {
            background: rgba(white, 0.3);
        }
    }
    &__content {
        padding: 20px;
    }
    .user-handler {
        margin-bottom: 0;
    }
    &--callback {
        .button {
            display: inline-block;
        }
        .title {
            margin-bottom: 4px;
        }
        .paragraph,
        .title {
            color: $creamWhite;
            margin-right: 1.5rem;
        }
    }
}

@media all and (max-width: 1079px) {
    .session-handler {
        position: relative;
    }
}

@media all and (max-width: 768px) {
    .session-handler {
        &__content {
            // .title {
            //     display: none;
            // }
            padding-bottom: 0;
            &:last-child {
                margin-bottom: 20px;
            }
        }
        &__button {
            height: 24px;
        }
    }
}
