.confirm-action {
    background-color: $fgGreen;
    position: absolute;
    width: auto;
    max-width: 31.25rem;
    height: auto;
    right: 0;
    top: 0;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.4);
    transform: translateY(-100%);
    opacity: 0;
    animation: confirm-action-dialogue 0.25s ease forwards;
    .paragraph {
        color: white;
        margin-bottom: 1.0625rem;
    }
    .title--h4 {
        color: white;
        font-weight: 600;
        margin-bottom: 0.75rem;
        margin-top: 0;
    }
    &__content {
        padding: 1.375rem;
    }
    .button {
        margin-bottom: 0;
    }
    .button--cancel {
        min-width: initial;
        margin-left: 1.3125rem;
    }
    &:after {
        content: '';
        border-top: 1.125rem solid $dark;
        border-left: 1.125rem solid transparent;
        border-right: 1.125rem solid transparent;
        position: absolute;
        right: 6.25rem;
    }
}

@media all and (max-width: 768px) {
    .confirm-action {
        .button--cancel {
            margin-left: 0;
            margin-top: 0.9375rem;
        }
    }
}
