.modal {
    height: 100%;
    &__content {
        height: 100%;
        filter: blur(0);
    }
    &__wrapper {
        width: 800px;
        max-width: 90%;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        pointer-events: all;
        position: relative;
        max-height: calc(100vh - 200px);
    }
    &__close-handler {
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 1;
        cursor: pointer;
    }
    &__close {
        position: absolute;
        bottom: -70px;
        right: 0;
    }
    &:after {
        content: '';
        transition: background 0.25s 0.25s ease;
        background: transparent;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
    }
    &--open {
        .modal {
            overflow: hidden;
            &__box {
                position: fixed;
                left: 0;
                right: 0;
                bottom: 0;
                top: 0;
                margin: auto;
                z-index: 2;
                display: flex;
                justify-content: center;
                align-items: center;
                pointer-events: none;
            }
            &__content {
                overflow: hidden;
                transition: filter 0.5s ease;
                transition: background 0.25s ease;
                filter: blur(5px);
            }
        }
        &:after {
            content: '';
            transition: background 0.25s ease;
            background: rgba(black, 0.6);
            pointer-events: all;
        }
        .content__main {
            overflow: hidden;
        }
    }
}

@media all and (max-width: 768px) {
    .modal {
        &__wrapper {
            overflow: auto;
            max-width: 95%;
            max-height: calc(100vh - 200px);
        }
    }
}
