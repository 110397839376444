@keyframes appear {
    from {
        opacity: 0;
        transform: translateY(-0.3125rem);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes hint-appear {
    from {
        opacity: 0;
        transform: translateY(-96%);
    }
    to {
        opacity: 1;
        transform: translateY(calc(-100% - 10px));
    }
}

@keyframes slide-in {
    from {
        opacity: 0;
        transform: translateY(100%);
    }
    25% {
        opacity: 0;
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes pop {
    from {
        opacity: 0;
        transform: translateY(-0.9375rem) scaleY(0);
    }
    to {
        opacity: 1;
        transform: translateY(0) scaleY(1);
    }
}

@keyframes shake {
    10%,
    90% {
        transform: translate3d(-0.0625rem, 0, 0);
    }

    20%,
    80% {
        transform: translate3d(0.125rem, 0, 0);
    }

    30%,
    50%,
    70% {
        transform: translate3d(-0.25rem, 0, 0);
    }

    40%,
    60% {
        transform: translate3d(0.25rem, 0, 0);
    }
}

@keyframes mini-shake {
    10%,
    90% {
        transform: rotate(-5deg);
    }

    20%,
    80% {
        transform: rotate(5deg);
    }

    30%,
    50%,
    70% {
        transform: rotate(-15deg);
    }

    40%,
    60% {
        transform: rotate(15deg);
    }
}

@keyframes previousArrow {
    from {
        transform: translateX(0);
    }
    50% {
        transform: translateX(-0.625rem);
    }
    to {
        transform: translateX(0);
    }
}

@keyframes nextArrow {
    from {
        transform: translateX(0);
    }
    50% {
        transform: translateX(0.625rem);
    }
    to {
        transform: translateX(0);
    }
}

@keyframes bank-id-expand {
    from {
        transform: scale(1);
        opacity: 1;
    }
    66% {
        transform: scale(3);
        opacity: 0;
    }
    to {
        transform: scale(3);
        opacity: 0;
    }
}

@keyframes confirm-action-dialogue {
    from {
        opacity: 0;
        transform: translateY(-90%);
    }
    to {
        opacity: 1;
        transform: translateY(-100%);
    }
}
