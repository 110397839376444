/**** Custom styles for noUiSlider ****/
.noUi-connect {
    background: none !important;
    background-color: $fgGreen !important;
}

.noUi-horizontal .noUi-handle,
.noUi-vertical .noUi-handle {
    background: white;
}
.noUi-target.noUi-horizontal .noUi-tooltip {
    background-color: white;
}

.noUi-handle-lower {
    border-radius: 50% !important;
    box-shadow: none;
}

.noUi-handle-lower::after {
    content: initial !important;
}

.noUi-handle-lower::before {
    content: initial !important;
}

.noUi-horizontal .noUi-handle {
    width: 28px !important;
    height: 28px !important;
}

.noUi-active {
    box-shadow: none;
}

/**** Custom styles for Range ****/
input[type='range']::-webkit-slider-thumb {
    background-color: skyblue;
}
input[type='range']::-moz-range-thumb {
    background-color: skyblue;
}
input[type='range']::-ms-thumb {
    background-color: skyblue;
}
/* For the globe and the text inside */
input[type='range'] + .thumb {
    background-color: blue;
}
input[type='range'] + .thumb.active .value {
    color: #fff;
}
