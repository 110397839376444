// Prevent OSX "overscroll"
* {
    box-sizing: border-box;
}
html {
    overflow: hidden;
}
body {
    overflow: auto;
}

// Use 100% of page, always
html,
body,
#root {
    width: 100%;
    height: 100%;
}

body {
    margin: 0;
    padding: 0;
}

form,
iframe {
    width: 100%;
}

.switch-wrapper {
    display: grid;
    grid-template-areas: 'MyCustomAreaName';
    position: relative;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: $creamWhite;
}

.switch-wrapper > div {
    grid-area: MyCustomAreaName;
    width: 100%;
}

.hidden {
    position: absolute;
    top: -100vh;
    overflow: hidden;
    width: 0;
    height: 0;
}

.ratio {
    width: 100%;
    height: 0;
    padding-top: 56.25%;
    position: relative;
    &__content {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.warning-icon,
.confirmation-icon {
    margin: auto;
    display: block;
    width: 72px;
    margin-bottom: 4rem;
    &--small {
        width: 35px;
        margin-bottom: 1rem;
    }
}
.warning-icon {
    animation: shake 1s ease;
}

.itor-frame {
    &--hidden {
        height: 0;
        position: absolute;
        width: 0;
        overflow: hidden;
        top: -100vh;
    }
}

.itor-frame {
    margin-top: 3rem;
}

.generic-tooltip {
    background-color: $fgGreen;
    border-radius: 20px !important;
    max-width: 300px;
}
