$spruce: #0d394b;
$blue: #3292ba;
$tealish: #1db8b1;
$fgNotQuiteYellow: #f9f871;
$meadow: #179e5a;
$saffron: #f5b13e;
$lipstick: #ce2066;

$scroll: #55d295;
$scrollTrack: #cfdbdb;
$dark: #273047;
$darkish: #a06279;
$light: #b4c2cd;
$lynch: #667b98;
$gull: #93a6b8;
$lightBg: #eaebed;
$blueBg: lighten($blue, 50%);
$fogGreen: #899c9c;
$lightTurquoise: #f1f5f5;
$aqua: #e0ecec;
$creamWhite: #fffefc;
$fgGreen: #0d5257;

$textColor: #0d5257;

$account-gradient: linear-gradient(-225deg, #5732ba 0%, #3257ba 100%);
$aqua: #e0ecec;
