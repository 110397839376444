.commitment-picker {
    background: $fgGreen;
    border-radius: 37px;
    color: $creamWhite;
    padding: 33px;
    &__list {
        margin: 0;
        padding: 0;
        list-style: none;
        width: 100%;
        max-height: calc(100vh - 400px);
        overflow-x: auto;
    }
    &__item {
        appearance: none;
        background: none;
        border: none;
        display: flex;
        width: 100%;
        justify-content: space-between;
        border-bottom: 1px solid rgba(255, 255, 255, 0.5);
        align-items: center;
        transition: background 0.5s ease;
        cursor: pointer;
        &:last-child {
            border-bottom: none;
        }
        &:hover {
            background: rgba(0, 0, 0, 0.2);
        }
        &--disabled {
            pointer-events: none;
        }
    }
    &__inline-spinner {
        position: absolute;
        right: 0;
        top: 0;
        width: 40px;
        height: 40px;
        .spinner {
            width: 40px;
            height: 40px;
        }
    }
    &__panel {
        flex-basis: 31%;
        padding: 1rem 0;
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: initial;
        &:first-child {
            flex-grow: 2;
            padding-left: 1rem;
        }
        &:last-child {
            display: block;
            text-align: right;
            padding-right: 1rem;
        }
        .paragraph,
        .caption {
            margin-bottom: 0;
            font-style: normal;
        }
    }
    &__arrow {
        display: inline-block;
        vertical-align: middle;
    }
    .paragraph,
    .caption,
    .title,
    .link {
        color: white;
    }
    > .spinner {
        position: static;
        margin: 0 auto;
        display: block;
    }
    .title--h2 {
        position: relative;
        padding-right: 45px;
    }
    .title--h4 {
        margin-top: 1rem;
    }
}

.commitment-picker .button--action {
    border: 2px solid white;
    color: white;
}

@media all and (max-width: 768px) {
    .commitment-picker {
        &__item {
            flex-direction: column;
            padding: 0.5rem 0;
            align-items: flex-start;
            position: relative;
            padding-right: 43px;
        }
        &__panel {
            padding: 0 1rem;
            &:last-child {
                display: flex;
                justify-content: center;
                position: absolute;
                right: 0;
                top: 0;
                bottom: 0;
                margin: auto;
            }
        }
    }
}
