.select {
    display: block;
    position: relative;
    min-height: 3.125rem;
    &__box {
        @extend .input__field;
        appearance: none;
        border: none;
        background: none;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }

    &:after {
        content: '';
        opacity: 0.7;
        background: url(/img/down.svg);
        position: absolute;
        right: 1.25rem;
        top: 0;
        bottom: 0;
        margin: auto;
        width: 1.25rem;
        height: 1.25rem;
        background-repeat: no-repeat;
        background-size: 100%;
        background-position: center;
    }
    &--error {
        @extend .input--error;
        .select__box {
            outline: $lipstick auto 0.3125rem;
        }
    }
    &--disabled {
        .select {
            &__box {
                opacity: 0.8;
                background-color: darken(white, 5%);
            }
        }
    }
}
