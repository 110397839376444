@import '../modules/button';

.bankId_wrapper button {
    @extend .button;
    font-weight: 100;
    margin-bottom: 5px;
}
.bankId_wrapper .qr-code svg {
    all: unset;
}
.bankId_backdoor {
    text-align: center;
    height: 100%;
}

.backdoor_bankId_header {
    margin: 0px;
    padding: 0px;
}
.backdoor_bankId_disclaimer {
    padding: 1rem;
}
